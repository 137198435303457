import { ReactElement, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import useAuthStore from '../../store/useAuth';

function OAuth2(): ReactElement {
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const searchParamCode = searchParams.get('code');

  const onOAuth2 = useAuthStore(state => state.onOAuth2);
  const getInfoAboutCurrentUserFromAPI = useAuthStore(state => state.getInfoAboutCurrentUserFromAPI);

  const { mutate } = useMutation<void, Error>(async () => {
    await onOAuth2(searchParamCode);
    await getInfoAboutCurrentUserFromAPI();
  }, {
    onSuccess: () => {
      history.push('/main');
    },
    retry: false,
  });

  useEffect(() => {
    if (!searchParamCode)
      return history.push('/login');

    mutate();
  }, [mutate, history, searchParamCode]);

  return <></>;
}

export default OAuth2;
