import notification from 'antd/lib/notification';
import rightIcon from '../assets/images/inline/reward_right.png';
import wrongIcon from '../assets/images/inline/reward_wrong.png';
import styled from '@emotion/styled';

const notificationStyle = {
  boxShadow: '0px 10px 40px rgba(23, 63, 112, 0.1)',
  borderRadius: '8px',
};

notification.config({
  placement: 'topRight',
  duration: 5,
});

export const NotificationIcon = styled.img`
  height: 60px;
  width: 60px;
`;

export const createSuccessNotification = (description: string): void => {
  notification.close(description);

  notification.success({
    message: 'Sucesso!',
    description: description,
    style: notificationStyle,
    icon: <NotificationIcon src={rightIcon} alt="O icone de certo."/>,
  });
};

export const createErrorNotification = (description: string): void => {
  notification.close(description);

  notification.error({
    key: description,
    message: 'Oops...',
    description: description,
    style: notificationStyle,
    icon: <NotificationIcon src={wrongIcon} alt="O icone de errado."/>,
  });
};
