import styled from '@emotion/styled';
import { hideDrag } from '../../styles/hide-drag';

export const Button = styled.button<{ loading?: boolean }>`
  min-width: 8em;

  padding: 0;
  outline: none;
  border: none;

  background-color: transparent;

  cursor: pointer;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: opacity 300ms ease,
  filter 300ms ease,
  -webkit-filter 300ms ease;

  ${props => !!props.loading && `
    cursor: wait;
  `};

  &:disabled {
    opacity: .6;
    cursor: no-drop;
  }

  ${hideDrag};
`;

export const ButtonImage = styled.img<{ wrapWidth?: boolean }>`
  height: ${props => props.wrapWidth ? 5.5 : 4}em;
  min-width: 8em;

  object-fit: contain;

  ${hideDrag};
`;

export const ButtonContent = styled.div`
  position: absolute;

  top: 26%;
  left: 11%;

  height: 50%;
  width: 78%;

  color: ${props => props.theme.colors.primary100};
`;

export const TextContainer = styled.div<{ primary?: boolean }>`
  font-size: 1em;

  .ant-spin-dot-item {
    background-color: ${props => props.primary ? '#FFF' : props.theme.colors.primary800};
  }

  ${props => props.primary && `
    font-family: var(--text-font-family);
    border-radius: 30px;
    
    color: white;
  `}
  .ant-spin-nested-loading, .ant-spin-blur, .ant-spin-container {
    height: 100%;
    width: 100%;

    border-radius: 20px;
  }

  &, .ant-spin-container {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Text = styled.span<{ whitespace: string }>`
  text-transform: uppercase;
  max-width: calc(100% - .5rem);
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: ${props => props.whitespace};
`;
