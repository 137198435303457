import React, { ReactElement, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import PublicRoute from './components/PublicRoute';
import OAuth2 from './pages/OAuth2';
import useAuthStore from './store/useAuth';
import PrivateRoute from './components/PrivateRoute';
import SCScreenLoading from './components/SCScreenLoading';
import SCServiceWorkerManager from './components/SCServiceWorkerManager';
import useAppEffects from './hooks/useAppEffects';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const CharacterPage = React.lazy(() => import('./pages/CharacterPage'));
const SpineTest = React.lazy(() => import('./pages/SpineTest'));
const GamePage = React.lazy(() => import('./pages/GamePage'));

function AppRouting(): ReactElement | null {
  useAppEffects();

  const isLogged = useAuthStore((state) => state.isLogged);

  if (isLogged === null) return null;

  return (
    <Suspense fallback={<SCScreenLoading />}>

      <SCServiceWorkerManager />

      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/">
            <HomePage/>
          </PublicRoute>
          <PublicRoute path="/oauth2">
            <OAuth2/>
          </PublicRoute>
          <Route path="/spine">
            <SpineTest/>
          </Route>
          <PrivateRoute path="/character">
            <CharacterPage/>
          </PrivateRoute>
          <PrivateRoute path="/main">
            <GamePage/>
          </PrivateRoute>

          <Route default>
            <Redirect to="/"/>
          </Route>
        </Switch>
     </BrowserRouter>
    </Suspense>
  );
}

export default AppRouting;
