export function validateCPF(cpf: string): void {
  if (typeof cpf !== 'string')
    emitError();

  cpf = cpf.replace(/\D+/g, '');

  if (cpf.length !== 11 || hasAllDigitsEqual(cpf))
    emitError();

  let sum = 0;
  let remainder;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11)
    remainder = 0;

  if (remainder !== parseInt(cpf.charAt(9), 10))
    emitError();

  sum = 0;

  for (let i = 0; i < 10; i++)
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11)
    remainder = 0;

  if (remainder !== parseInt(cpf.charAt(10), 10))
    emitError();
}

function emitError(): void {
  throw new Error('É necessário enviar um cpf valido.');
}

function hasAllDigitsEqual(cpf: string): boolean {
  return /^(\d)\1{10}$/.test(cpf);
}
